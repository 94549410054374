import instance from './axios.config';

const AssignmentService = {
    fetchByStudentUserCode: async (studentUserCode) => {
        try {
            const axios = await instance();
            const response = await axios.get(`/assignments/userCode/${studentUserCode}`);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    addAssignment: async (userCode, assignment) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/assignments/userCode/${userCode}`, assignment);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },
}

export default AssignmentService;