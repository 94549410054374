import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import UserService from '../../services/user.service';
import waitAndExecute from '../../utils/js-utils';
import { AppContext } from '../../context/app.context';

const SignIn = () => {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const user = await UserService.authenticate(username, password);
        if (user) {
            appContext.setCurrentUser(user);
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            navigate('/add-question');
        }

        setShowErrorMessage(true);
        await waitAndExecute(3000, () => setShowErrorMessage(false));
    };

    return (
        <Box display="flex"
            justifyContent="center"
            alignItems="center"
            p={5}
            sx={{
                border: '1px solid #ccc',
                borderRadius: '10px',
                width: '500px',
                maxWidth: '90%',
                height: '400px',
                maxHeight: '400px',
                margin: 'auto',
                bgcolor: 'background.paper',
                marginTop: '60px'
            }}>
            <form onSubmit={handleSubmit}>
                <Typography variant="h6" component="h2" padding={2}>
                    Authentification
                </Typography>

                {showErrorMessage &&
                    <Alert severity="error">Le compte utilisateur et le mot de passe ne correspondent pas!</Alert>
                }

                <TextField
                    label="Compte"
                    variant="outlined"
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    fullWidth
                />
                <TextField
                    label="Mot de passe"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                />
                <Button
                    type="submit"
                    variant="contained"
                    margin="dense"
                    color="primary"
                    startIcon={<LockOpenIcon />}
                >
                    Connexion
                </Button>
            </form>
        </Box>
    );
}

export default SignIn;