
import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material/';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import PsnDropdown from "../components/psn-dropdown.component";
import ParamService from '../../services/param.service';
import waitAndExecute from '../../utils/js-utils'
import AssignmentService from '../../services/assignment.service';

const AddAssignment = ({ studentUserCode, updateAssignments, handleToggle }) => {
    const [subjectId, setSubjectId] = useState('');
    const [assignmentName, setAssignmentName] = useState('');
    const [errors, setErrors] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleChangeSubject = (e) => {
        setSubjectId(e.target.value);
    };

    const handleChangeAssignmentName = (e) => {
        setAssignmentName(e.target.value);
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.assignmentName = assignmentName ? '' : 'This field is required';
        tempErrors.subjectId = subjectId ? '' : 'Subject is required';
        setErrors(tempErrors);

        console.log(JSON.stringify(errors));
        return Object.values(tempErrors).every(x => x === '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const savedAssignment = await AssignmentService.addAssignment(studentUserCode, {subjectId, name: assignmentName, authorId: 5})
            console.log(JSON.stringify(savedAssignment));
            setShowSuccessMessage(true);
            setSubjectId('');
            setAssignmentName('');

            updateAssignments({ id: savedAssignment.assignmentId, name: assignmentName });
            await waitAndExecute(2000, () => setShowSuccessMessage(false));
            handleToggle();
        }

        console.log('Form data: ', subjectId, assignmentName);
    };

    return (

        <div>
            {showSuccessMessage &&
                <Alert severity="info">Operation effectuee avec succes</Alert>
            }

            <form onSubmit={handleSubmit}>
                <PsnDropdown
                    name="subject"
                    value={subjectId}
                    label={'Matiere'}
                    handleChange={handleChangeSubject}
                    fetchFunction={ParamService.fetchAllSubjects}
                    valueLabelMap={{ value: 'id', label: 'name' }}
                    config={{
                        error: !!errors.subjectId,
                        helperText: errors.subjectId
                    }} />
                <TextField
                    label="Saisir le nom de l'exercice!"
                    name="assignmentName"
                    value={assignmentName}
                    onChange={handleChangeAssignmentName}
                    margin="normal"
                    variant="outlined"
                    error={!!errors.assignmentName}
                    helperText={errors.assignmentName}
                    fullWidth
                />
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Button onClick={handleToggle} variant="outlined" color="primary" startIcon={<ArrowBackIosIcon />}>
                            Annuler
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary" margin="normal" startIcon={<SaveIcon />}>
                            Enregistrer
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </div>

    );
}

export default AddAssignment;