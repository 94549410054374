
import React, { useState, useEffect } from 'react';
import { Container, Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Paper, Button, styled, Box } from '@mui/material/';
import AddIcon from '@mui/icons-material/Add';
import { ExpandMore } from '@mui/icons-material';
import AddQuestionForm from './add-question-form';
import SelectAssignment from './select-assignment';
import AddAssignment from './add-assignment';
import AssignmentService from '../../services/assignment.service';
import SelectStudent from './select-student';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const AddQuestion = () => {
    const [assignmentTitle, setAssignmentTitle] = useState('Ajouter un exercice');
    const [toggle, setToggle] = useState(false);

    const [assignments, setAssignments] = useState(undefined);
    const [selectedAssignment, setSelectedAssignment] = useState(undefined);
    const [selectedStudent, setSelectedStudent] = useState(undefined);
    const [selectedStudentFirstName, setSelectedStudentFirstName] = useState(undefined);
    const [selectedAssignmentName, setSelectedAssignmentName] = useState(undefined);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const data = await AssignmentService.fetchByStudentUserCode(selectedStudent);
                setAssignments(data);

                const [assignment] = data;
                if (assignment) {
                    setSelectedAssignment(assignment.id);
                    setAssignmentTitle(selectedStudentFirstName + ': ' + assignment.name);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [selectedStudent]);

    const handleToggle = () => {
        setToggle(!toggle);
    };

    const updateAssignments = (assignment) => {
        const newAssignments = [assignment, ...assignments];
        setAssignments(newAssignments);
        setSelectedAssignment(assignment.id);
        setSelectedAssignmentName(assignment.name);
        setAssignmentTitle(selectedStudentFirstName + ': ' + assignment.name);
    };

    return (
        <Container>
            <h1>Ajout de questions</h1>

            <div>
                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{assignmentTitle}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={!toggle ? 12 : 6} >
                                <StyledPaper fullwidth={!toggle}>
                                    <Typography variant="h6" component="h2" padding={2}>
                                        Veuillez, si necessaire selectionner l'exercice pour lequel vous voulez assigner la prochaine question
                                    </Typography>

                                    <Box display="flex" justifyContent="flex-end">
                                        <Button
                                            onClick={handleToggle}
                                            color="primary"
                                            variant="contained"
                                            disabled={!selectedStudentFirstName || toggle}
                                            startIcon={<AddIcon />}
                                        >
                                            Ajouter un exercice ({selectedStudentFirstName})
                                        </Button>
                                    </Box>
                                    <SelectStudent
                                        selectedStudent={selectedStudent}
                                        setSelectedStudent={setSelectedStudent}
                                        selectedAssignmentName={selectedAssignmentName}
                                        setSelectedStudentFirstName={setSelectedStudentFirstName}
                                        setAssignmentTitle={setAssignmentTitle}
                                        config={{
                                            error: !!errors.student,
                                            helperText: errors.student
                                        }}
                                    />

                                    <SelectAssignment
                                        selectedAssignment={selectedAssignment}
                                        setSelectedAssignment={setSelectedAssignment}
                                        selectedStudentFirstName={selectedStudentFirstName}
                                        assignments={assignments}
                                        setAssignmentTitle={setAssignmentTitle}
                                        config={{
                                            error: !!errors.assignment,
                                            helperText: errors.assignment
                                        }}
                                    />

                                    {/* <Box display="flex" justifyContent="flex-end">
                                        <Button variant="contained" color="primary" endIcon={<ArrowForwardIosIcon />}>
                                            Suivant
                                        </Button>
                                    </Box> */}
                                </StyledPaper>
                            </Grid>
                            {toggle && (
                                <Grid item xs={6}>
                                    <StyledPaper>
                                        <Typography variant="h6">Ajout d'un exercice</Typography>
                                        <AddAssignment studentUserCode={selectedStudent} updateAssignments={updateAssignments} handleToggle={handleToggle} />
                                    </StyledPaper>
                                </Grid>
                            )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Questions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AddQuestionForm
                            selectedStudent={selectedStudent}
                            selectedAssignment={selectedAssignment}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container>
    );
}

export default AddQuestion;