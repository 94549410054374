import React, { useState } from "react";
import Swal from "sweetalert2";

const ContactUs = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const nameChangeHandler = (event) => {
    setName(event.target.value);
  }
  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
  }
  const messageChangeHandler = (event) => {
    setMessage(event.target.value);
  }


  const submitHandler = (event) => {
    event.preventDefault();

    const contact = {
      name: name,
      email: email,
      message: message
    };
    console.log(contact);

    Swal.fire("Thanks a lot!", "Form submitted successfully.", "success");

    setName('');
    setEmail('');
    setMessage('');
  }

  return (
    <>
      <div className="container py-5">
        <h3 className="text-primary fw-bold text-center">Nous joindre</h3>
        <div className="row mt-4">
          <div className="col-lg-10 offset-lg-1">
            <div className="row gx-md-5 mx-2">
              {/* <div className="col-md-6">
                <h4 className="fw-bold">Envoyez nous un message</h4>
                <form onSubmit={submitHandler} className="my-3" action="" method="get">
                  <div class="mb-3">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Votre nom"
                      onChange={nameChangeHandler}
                      value={name}
                      required
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder="nom@example.com"
                      onChange={emailChangeHandler}
                      value={email}
                      required
                    />
                  </div>
                  <div class="mb-3">
                    <textarea
                      class="form-control"
                      rows="4"
                      cols="4"
                      name="message"
                      placeholder="Votre message"
                      onChange={messageChangeHandler}
                      value={message}
                      required
                    ></textarea>
                  </div>
                  <div class="mb-3">
                    <button type="submit" className="btn btn-primary">Envoyer le message</button>
                  </div>
                </form>
              </div> */}
              <div className="col-md-6">
                <h4 className="fw-bold">Contact</h4>
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <p>
                      <i class="fa-solid fa-location-dot me-2"></i>1597 rue des achillées, Québec, CA G3K 0S3
                    </p>
                  </li>
                  <li class="nav-item">
                    <p>
                      <i class="fa-solid fa-phone me-2"></i>(581) 999-4864
                    </p>
                  </li>
                  <li class="nav-item">
                    <p>
                      <i class="fa-solid fa-envelope me-2"></i>spomyn@gmail.com
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs
