import {
  Routes,
  Route,
  useNavigate,
  createSearchParams,
} from "react-router-dom";

import Navbar from "./components/header/Navbar";
import NotFound from "./pages/not-found/NotFound";

import ContactUs from "./pages/contact-us/ContactUs";
import AboutUs from "./pages/about-us/AboutUs";
import PrivacyPolicy from "./pages/privacy-policy";
import AddQuestion from "./pages/questions/add-question";
import SignIn from "./pages/authentication/sign-in";
import RemoveAccountInfo from "./pages/public/remove-account-info";

function App() {
  const navigate = useNavigate();

  const onSearch = (searchQuery) => {
    navigate(`/?${createSearchParams({ q: searchQuery })}`);
  };

  return (
    <>
      <Navbar onSearch={onSearch} />
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/add-question" element={<AddQuestion />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/remove-account-info" element={<RemoveAccountInfo />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
