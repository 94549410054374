import { createContext, useState } from "react";

export const AppContext = createContext({
    currentUser: {},
    setCurrentUser: () =>{}
});

function AppContextProvider({ children }) {
    const [currentUser, setUser] = useState(undefined);

    const setCurrentUser = (user) => {
        setUser(user);
    }

    const value = {
        currentUser: currentUser,
        setCurrentUser: setCurrentUser
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppContextProvider;