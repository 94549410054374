import instance from './axios.config';
import { encode } from 'base-64';

const UserService = {
    fetchStudentByParentId: async (parentId) => {
        try {
            const axios = await instance();
            const response = await axios.get(`/users/${parentId}/students`);
            const users = response.data;

            return users;
        } catch (error) {
            console.log('Fetch error: ' + error);
        }
    },

    getUserInfo: async () => {
        try {
            const axios = await instance();
            const response = await axios.get(`/users/me`);
            const user = response.data;

            return user;
        } catch (error) {
            console.log('Fetch error: ' + error);
        }
    },

    findUserByCode: async (userCode) => {
        try {
            const axios = await instance(true);
            const response = await axios.get(`/users/user/${userCode}`);
            const user = response.data;

            return user;
        } catch (error) {
            console.log('Fetch error: ' + error);
        }
    },

    authenticate: async (username, password) => {
        try {
            const axios = await instance(true);
            const credentials = encode(username + ':' + password);
            const headers = { 'Authorization': `Basic ${credentials}` };
            const response = await axios.get(`/users/token`, { headers });
            const user = response.data;

            return user;
        } catch (error) {
            console.log('Fetch error: ' + error);
        }
    },
}

export default UserService;