import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container py-5">
        <h3 className="text-primary fw-bold">Politique de Confidentialité</h3>
        <p>
          SPOMYN est conçu pour l'apprentissage des enfants.
          La protection de la vie privée de nos utilisateurs, en particulier des enfants,
          est d'une importance capitale pour nous. Cette politique de confidentialité explique
          les informations que nous collectons, comment nous les utilisons et les mesures que
          nous prenons pour les protéger.
        </p>

        <h4>Informations que nous collectons:</h4>
        <ol>
          <li>
            Informations sur l'Élève:
            <ul>
              <li>Nous collectons le nom, le genre et le niveau scolaire de l'élève.</li>
              <li>Ces informations sont utilisées uniquement dans le but d'adapter l'expérience éducative offerte par l'application.</li>
            </ul>
          </li>
          <li>
            Informations sur les Parents:
            <ul>
              <li>Nous demandons l'email et le numéro de téléphone des parents ou tuteurs légaux.</li>
              <li>Ces informations sont utilisées pour communiquer avec les parents concernant l'utilisation de l'application par leur enfant.</li>
            </ul>
          </li>
        </ol>

        <h4>Utilisation des Informations:</h4>
        <ul>
          <li>Les informations collectées ne sont utilisées que pour les fins décrites dans cette politique.</li>
          <li>Nous ne vendons ni ne partageons vos informations avec des tiers à des fins commerciales.</li>
        </ul>

        <h4>Sécurité des Informations:</h4>
        <ul>
          <li>Nous prenons des mesures strictes pour protéger les informations de nos utilisateurs, en particulier des enfants.</li>
          <li>L'accès aux informations personnelles est limité et protégé par des mesures de sécurité appropriées.</li>
        </ul>

        <h4>Consentement des Parents:</h4>
        <ul>
          <li>Le consentement des parents est requis avant de collecter les informations des enfants.</li>
          <li>Les parents peuvent réviser ou demander la suppression des informations de leur enfant à tout moment.</li>
        </ul>

        <h4>Modifications de la Politique de Confidentialité:</h4>
        <ul>
          <li>Cette politique de confidentialité peut être mise à jour périodiquement. Les utilisateurs seront informés de toute modification importante.</li>
        </ul>

        <h4>Contact:</h4>
        <ul>
          <li>Pour toute question concernant cette politique, veuillez nous contacter à spomyn@prosinext.com</li>
        </ul>
      </div>
    </>
  );
}

export default PrivacyPolicy