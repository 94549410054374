import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PsnRichTextEditor = ({ config }) => {
    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    const quillStyle = {
        border: config.error ? '1px solid red' : '1px solid #ccc',
      };

    return (
        <div style={quillStyle}>
            <ReactQuill modules={modules} {...config} />
            {config.error && <span style={{ color: 'red' }}>{config.helperText}</span>}
        </div>

    );
};

export default PsnRichTextEditor;
