import React, { useContext, useState } from 'react';
import { TextField, Button, Box } from '@mui/material/';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import 'react-quill/dist/quill.snow.css';

import ParamService from '../../services/param.service';
import PsnDropdown from '../components/psn-dropdown.component';
import PsnRichTextEditor from '../components/psn.rich-text-editor.component';
import waitAndExecute from '../../utils/js-utils';
import QuestionService from '../../services/question.service';
import { AppContext } from '../../context/app.context';

const AddQuestionForm = ({ selectedStudent, selectedAssignment, errors, setErrors }) => {
    const appContext = useContext(AppContext);
    const [isFormattingEnabled, setFormattingEnabled] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [formData, setFormData] = useState({
        subject: '',
        question_type: '',
        question: '',
        answer: '',
    });

    const handleChangeRichText = (content, delta, source, editor) => {
        setFormData({
            ...formData,
            question: editor.getLength() > 1 ? content : ''
        });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleChangeQuestionType = (e) => {
        const value = e.target.value;
        if (value === 'OPENED_QUESTION') {
            setFormattingEnabled(true);
        } else {
            setFormattingEnabled(false);
        }

        setFormData({
            ...formData,
            [e.target.name]: value
        });
    };

    const validateAssignment = (assignment) => assignment ? '' : 'Le champ "Exercice" est obligatoire';
    const validateStudent = (student) => student ? '' : 'Le champ "Eleve" est obligatoire';
    const validateQuestionType = (question_type) => question_type ? '' : 'Le champ "Type de question" est obligatoire';
    const validateQuestion = (question) => question ? '' : 'Le champ "Question" est obligatoire';
    const validateAnswer = (answer) => '';

    const validate = () => {
        let tempErrors = {};
        tempErrors.assignment = validateAssignment(selectedAssignment);
        tempErrors.student = validateStudent(selectedStudent);
        tempErrors.question_type = validateQuestionType(formData.question_type);
        tempErrors.question = validateQuestion(formData.question);
        tempErrors.answer = validateAnswer(formData.answer);
        setErrors(tempErrors);

        return Object.values(tempErrors).every(x => x === '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const parentId = appContext.currentUser.id;
            const savedQuestion = await QuestionService.addQuestion(parentId, {
                assignmentId: selectedAssignment,
                questionTypeCode: formData.question_type,
                text: formData.question,
                authorAnswer: formData.answer
            });

            resetForm();

            setShowSuccessMessage(true);
            await waitAndExecute(2000, () => setShowSuccessMessage(false));
        } else {
            console.log(errors);
            if (formData.question === '') {
                setFormData({
                    ...formData,
                    question: undefined
                });
            }
            setShowErrorMessage(true);
            await waitAndExecute(2000, () => setShowErrorMessage(false));
        }
    };

    const resetForm = () =>{
        setFormData({
            ...formData,
            question: '',
            answer: '',
        });
    }

    return (
        <div>
            {showSuccessMessage &&
                <Alert severity="info">Operation effectuee avec succes</Alert>
            }

            {showErrorMessage &&
                <Alert severity="error">Il y a des champs en erreur!!!</Alert>
            }

            <form onSubmit={handleSubmit}>
                <PsnDropdown
                    name="question_type"
                    value={formData.question_type}
                    label={'Type de question'}
                    handleChange={handleChangeQuestionType}
                    fetchFunction={ParamService.fetchAllQuestionTypes}
                    valueLabelMap={{ value: 'typeCode', label: 'typeName' }}
                    config={{
                        error: !!errors.question_type,
                        helperText: errors.question_type
                    }} />
                {
                    isFormattingEnabled
                        ? <PsnRichTextEditor
                            config={{
                                placeholder: 'Saisir votre question ici!',
                                value: formData.question,
                                onChange: handleChangeRichText,
                                theme: 'snow',
                                name: 'question',
                                error: !!errors.question,
                                helperText: errors.question
                            }}
                        />
                        : <TextField
                            label="Saisir votre question ici!"
                            name="question"
                            value={formData.question}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            error={!!errors.question}
                            helperText={errors.question}
                        />
                }

                <TextField
                    label="Saisir la reponse ici!"
                    name="answer"
                    value={formData.answer}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    error={!!errors.answer}
                    helperText={errors.answer}
                />

                <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" variant="contained" color="primary" margin="normal" startIcon={<SaveIcon />}>
                        Enregistrer
                    </Button>
                </Box>
            </form>
        </div>
    );
}

export default AddQuestionForm;