import { useState, useEffect } from 'react';
import { TextField, MenuItem, } from '@mui/material/';

import UserService from '../../services/user.service';

const SelectStudent = ({ selectedStudent, setSelectedStudent, setSelectedStudentFirstName, selectedAssignmentName, setAssignmentTitle, config }) => {
    const [students, setStudents] = useState(undefined);


    useEffect(() => {
        (async () => {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                const data = await UserService.fetchStudentByParentId(currentUser.id);
                setStudents(data);

                const [first] = data;
                if (first) {
                    setSelectedStudent(first.userCode);
                    setSelectedStudentFirstName(first.firstName);
                }

            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;
        const [first] = students.filter((elt) => elt.userCode === value);
        setSelectedStudent(first.userCode);
        setSelectedStudentFirstName(first.firstName);
        setAssignmentTitle(first.firstName + ': ' + selectedAssignmentName);
    };

    return (
        <div>
            {
                students &&
                <TextField
                    select
                    label='Students'
                    name='student'
                    value={selectedStudent}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    {...config}
                >
                    {students.map(option => (
                        <MenuItem
                            value={option.userCode}
                            key={option.userCode}
                        >
                            {option.firstName}
                        </MenuItem>
                    ))}
                </TextField>
            }
        </div>
    );
}

export default SelectStudent;