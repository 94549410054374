import instance from './axios.config';

const QuestionService = {
    addQuestion: async (parentId, question) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/questions/author/${parentId}`, question);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },
}

export default QuestionService;