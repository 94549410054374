import * as React from 'react';
import { Box, Typography } from '@mui/material';

const RemoveAccountInfo = () => {
    return (
        <Box
            justifyContent="center"
            alignItems="center"
            p={5}
            sx={{ width: '100%', maxWidth: 900 }}>
            <Typography variant="h4" padding={2}>
                Comment supprimer mon compte?
            </Typography>

            <Typography variant="body1" padding={2}>
                <p>En votre qualité de parent, vous avez le droit de supprimer votre compte ainsi que celui de vos enfants à tout moment.</p>
                <p>Pour supprimer votre compte, vous devez suivre les étapes suivants:</p>
                <ol>
                    <li>Vous authentifier avec la version mobile de l'application</li>
                    <li>Taper sur l'onglet <b>Config</b></li>
                    <li>Supprimer l'un après l'autre, l'ensemble des comptes associés aux enfants que vous supervisez en tapant sur <b>Supprimer</b> à côté du nom de l'enfant.</li>
                    <li>Une fois que tous les comptes que vous supervisez auront été supprimés, le bouton <b>Supprimer mon compte</b> s'active et vous permet ainsi de supprimer votre compte</li>
                </ol>

                <p>Au terme de ces étapes, votre compte ainsi que celui de vos enfants sont supprimés de SPOMYN. 
                    Veuillez cependant noter que les exercices que vous avez créés ne sont pas supprimés. 
                    Ils vont rester dans l'application et d'autres utilisateurs pourront y avoir accès, incluant vous même, si vous décidez de revenir.
                    Par contre l'ensemble des sessions d'apprentissage ainsi que les réponses associées à ces exercices sont perdues à jamais. 
                    Vous perdez tout l'historique d'apprentissage de vos enfants.</p>

                    <p>Il est donc primordial d'y penser par deux fois avant d'amorcer cette opération irréversible. Nous vous conseillons au cas où vous 
                        voudriez prendre une pause de désactiver votre compte pendant un certain temps et reprendre plus tard quand vous serez prêt. De cette
                        façon, l'intégralité des données d'apprentissage de vos enfants est sauvegardée.
                    </p>
            </Typography>
        </Box>
    );
}

export default RemoveAccountInfo;