import React, { useEffect, } from 'react';
import { TextField, MenuItem, } from '@mui/material/';

const SelectAssignment = ({selectedAssignment, setSelectedAssignment, assignments, setAssignmentTitle, selectedStudentFirstName, config }) => {

    const handleChange = (e) => {
        const value = e.target.value;
        const [assignment] = assignments.filter((elt) => elt.id === value);
        setSelectedAssignment(assignment.id);
        setAssignmentTitle(selectedStudentFirstName + ': ' + assignment.name);
    };

    return (
        <div>
            {
                (assignments && selectedAssignment) &&
                <TextField
                    select
                    label='Exercices'
                    name='assignment'
                    value={selectedAssignment}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    {...config}
                >
                    {assignments.map(option => (
                        <MenuItem
                            value={option.id}
                            key={option.id}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            }
        </div>
    );
}

export default SelectAssignment;