import instance from './axios.config';

const ParamService = {
    fetchAllSubjects: async () => {
        try {
            const axios = await instance();
            const response = await axios.get('/subjects');

            return response.data;
        } catch (error) {
            console.log('Fetch error: ' + error);
        }
    },
    fetchAllQuestionTypes: async () => {
        try {
            const axios = await instance();
            const response = await axios.get('/question_types');

            return response.data;
        } catch (error) {
            console.log('Fetch error: ' + error);
        }
    },
    fetchAllAnswerTypes: async () => {
        try {
            const axios = await instance();
            const response = await axios.get('/answer_types');

            return response.data;
        } catch (error) {
            console.log('Fetch error: ' + error);
        }
    }
}

export default ParamService;